import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getSalesReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/exclusive-reports/exima/sales-report', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEfficiencyReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/exclusive-reports/exima/efficiency-report', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
